<template>
  <b-row class="match-height">
    <b-col
      v-for="machine in dataList"
      :key="machine.id"
      cols="12"
      md="6"
    >
      <machine-card
        :machine="machine"
        :is-report="isReport"
      />
    </b-col>
    <b-col
      v-if="!isReport"
      cols="12"
      md="6"
    >
      <b-card no-body>
        <b-card-body
          class="d-flex align-items-center justify-content-center text-center"
        >
          <b-button
            v-b-toggle.machine-sidebar
            variant="flat-primary"
            size="lg"
            class="p-5"
            @click="newMachine()"
          >
            <FeatherIcon
              icon="PlusCircleIcon"
              size="48"
            />
            <div class="font-medium-4 mt-1">
              Yeni Makina Ekle
            </div>
          </b-button>
        </b-card-body>
      </b-card>
    </b-col>
    <machine-sidebar />
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BButton, VBToggle,
} from 'bootstrap-vue'
import MachineCard from '@/views/Admin/Customers/View/CustomerMachines/MachineCard.vue'
import MachineSidebar from '@/views/Admin/Customers/MachineSidebar.vue'

export default {
  name: 'CustomerAddress',
  components: {
    BRow, BCol, BCard, BCardBody, BButton, MachineCard, MachineSidebar,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    isReport: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataList() {
      return this.$store.getters['customerMachines/dataList']
    },
    saveData() {
      return this.$store.getters['customerMachines/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.getData()
      } else if (val.status === false) {
        this.getData()
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    newMachine() {
      this.$store.commit('customerMachines/SET_DATA_ITEM', {
        id: null,
        title: null,
        serial: null,
        status: '1',
        notes: null,
        warranty_sdate: null,
        warranty_edate: null,
        head_warranty_sdate: null,
        head_warranty_edate: null,
        id_products: null,
        id_customers: this.$route.params.id,
        id_customer_addresses: null,
      })
    },
    getData() {
      this.$store.dispatch('customerMachines/getDataList', {
        select: [
          'customer_machines.id AS id',
          'products.title AS product',
          'customer_machines.title AS title',
          'customer_machines.serial AS serial',
          'customer_machines.status AS status',
          'customer_machines.notes AS notes',
          'customer_machines.warranty_sdate AS warranty_sdate',
          'customer_machines.warranty_edate AS warranty_edate',
          'customer_machines.head_warranty_sdate AS head_warranty_sdate',
          'customer_machines.head_warranty_edate AS head_warranty_edate',
          'customer_addresses.title AS address_title',
        ],
        where: {
          'customer_machines.id_customers': this.$route.params.id,
        },
      })
    },
  },
}
</script>
