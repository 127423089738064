<template>
  <b-card title="Faaliyet Alanları">
    <b-list-group>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Faaliyet Gösterilen Sektörler
        </div>
        <div>
          <b-badge
            v-for="sector in sectors"
            :key="sector.id"
            variant="light-success"
            class="mr-1"
          >
            {{ sector.sector }}
          </b-badge>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Üretim Faaliyetleri
        </div>
        <div>
          <b-badge
            v-for="activity in activities"
            :key="activity.id"
            variant="light-success"
            class="mr-1"
          >
            {{ activity.activity }}
          </b-badge>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'

export default {
  name: 'Activities',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BBadge,
  },
  computed: {
    sectors() {
      return this.$store.getters['customerSectors/dataList']
    },
    activities() {
      return this.$store.getters['customerActivities/dataList']
    },
  },
  created() {
    this.getSectors()
    this.getActivities()
  },
  methods: {
    getSectors() {
      this.$store.dispatch('customerSectors/getDataList', {
        where: {
          'customer_sectors.id_customers': this.$route.params.id,
        },
      })
    },
    getActivities() {
      this.$store.dispatch('customerActivities/getDataList', {
        where: {
          'customer_activities.id_customers': this.$route.params.id,
        },
      })
    },
  },
}
</script>
