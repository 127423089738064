<template>
  <validation-observer ref="simpleRules">
    <products />
    <title-name />
    <serial-no />
    <warranty-sdate />
    <warranty-edate />
    <head-warranty-sdate />
    <head-warranty-edate />
    <statuses />
    <customer-addresses />
    <notes />
    <b-button
      variant="primary"
      block
      @click="submitForm"
    >
      <FeatherIcon icon="SaveIcon" /> Kaydet
    </b-button>
  </validation-observer>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import Products from '@/views/Admin/Customers/View/CustomerMachines/elements/Products.vue'
import TitleName from '@/views/Admin/Customers/View/CustomerMachines/elements/Title.vue'
import SerialNo from '@/views/Admin/Customers/View/CustomerMachines/elements/Serial.vue'
import Statuses from '@/views/Admin/Customers/View/CustomerMachines/elements/Statuses.vue'
import CustomerAddresses from '@/views/Admin/Customers/View/CustomerMachines/elements/Addresses.vue'
import WarrantySdate from '@/views/Admin/Customers/View/CustomerMachines/elements/WarrantySdate.vue'
import WarrantyEdate from '@/views/Admin/Customers/View/CustomerMachines/elements/WarrantyEdate.vue'
import HeadWarrantySdate from '@/views/Admin/Customers/View/CustomerMachines/elements/HeadWarrantySdate.vue'
import HeadWarrantyEdate from '@/views/Admin/Customers/View/CustomerMachines/elements/HeadWarrantyEdate.vue'
import Notes from '@/views/Admin/Customers/View/CustomerMachines/elements/Notes.vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'Add',
  components: {
    BButton,
    Products,
    TitleName,
    SerialNo,
    Statuses,
    CustomerAddresses,
    WarrantySdate,
    WarrantyEdate,
    HeadWarrantySdate,
    HeadWarrantyEdate,
    Notes,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerMachines/dataItem']
    },
    saveData() {
      return this.$store.getters['customerMachines/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('customerMachines/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
