<template>
  <div>
    <b-tabs
      pills
      justified
    >
      <b-tab
        active
        lazy
      >
        <template #title>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon icon="FileTextIcon" />
            <span>Müşteri Detayı</span>
          </div>
        </template>
        <customer-info />
        <related-persons />
        <activities />
        <machine-parkour />
        <customer-invoice />
        <customer-address :is-report="true" />
        <customer-machines :is-report="true" />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon icon="MessageCircleIcon" />
            <span>Görüşmeler</span>
          </div>
        </template>
        <interviews />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon icon="FileTextIcon" />
            <span>Teklifler</span>
          </div>
        </template>
        <offers />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon icon="FileTextIcon" />
            <span>Proformalar</span>
          </div>
        </template>
        <proforma />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon icon="SettingsIcon" />
            <span>Servis</span>
          </div>
        </template>
        <services />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import RelatedPersons from '@/views/Admin/Customers/View/RelatedPersons.vue'
import Activities from '@/views/Admin/Customers/View/Activities.vue'
import MachineParkour from '@/views/Admin/Customers/View/MachineParkour.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerAddress from '@/views/Admin/Customers/View/CustomerAddress.vue'
import CustomerMachines from '@/views/Admin/Customers/View/CustomerMachines.vue'
import Interviews from '@/views/Admin/Reports/Customers/components/Interviews.vue'
import Offers from '@/views/Admin/Reports/Customers/components/Offers.vue'
import Proforma from '@/views/Admin/Reports/Customers/components/Proforma.vue'
import Services from '@/views/Admin/Reports/Customers/components/Services.vue'

export default {
  name: 'Detail',
  components: {
    BTabs,
    BTab,
    CustomerInfo,
    RelatedPersons,
    Activities,
    MachineParkour,
    CustomerInvoice,
    CustomerAddress,
    CustomerMachines,
    Interviews,
    Offers,
    Proforma,
    Services,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/dataItem']
    },
  },
  created() {
    this.getCustomer()
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/getDataItem', this.$route.params.id)
    },
  },
}
</script>
