<template>
  <b-card
    v-if="dataList.length > 0"
    no-body
  >
    <b-card-header>
      <b-card-title>Makina Parkuru</b-card-title>
    </b-card-header>
    <b-table
      :items="dataList"
      :fields="fields"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BTable,
} from 'bootstrap-vue'

export default {
  name: 'MachineParkour',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'parkour_type',
          label: 'PARKUR TİPİ',
        },
        {
          key: 'machine_type',
          label: 'MAKİNA TİPİ',
        },
        {
          key: 'quantity',
          label: 'ADET',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['customerMachineParkour/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('customerMachineParkour/getDataList', {
        where: {
          'customer_machine_parkour.id_customers': this.$route.params.id,
        },
      })
    },
  },
}
</script>
