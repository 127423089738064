<template>
  <b-card>
    <b-list-group class="mb-1">
      <list-item
        title="Ürün Kartı"
        :value="machine.product"
      />
      <list-item
        title="Tanım"
        :value="machine.title"
      />
      <list-item
        title="Seri No"
        :value="machine.serial"
      />
      <b-list-group-item v-if="machine.warranty_sdate || machine.warranty_edate">
        <div class="font-weight-bold text-primary">
          Makina Garanti Başlangıç - Bitiş
        </div>
        <div class="font-weight-light">
          {{ (machine.warranty_sdate? moment(machine.warranty_sdate).format('DD.MM.YYYY') : null) }}
          -
          {{ (machine.warranty_edate? moment(machine.warranty_edate).format('DD.MM.YYYY') : null) }}
        </div>
        <div class="font-weight-light text-success font-small-2">
          <span v-if="moment(machine.warranty_edate).diff(moment(),'days') > 0">
            {{ moment(machine.warranty_edate).diff(moment(),'days') }} gün kaldı
          </span>
          <span v-else>Garanti süresi bitmiş</span>
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="machine.warranty_sdate || machine.warranty_edate">
        <div class="font-weight-bold text-primary">
          Kafa Garanti Başlangıç - Bitiş
        </div>
        <div class="font-weight-light">
          {{ (machine.head_warranty_sdate? moment(machine.head_warranty_sdate).format('DD.MM.YYYY') : null) }}
          -
          {{ (machine.head_warranty_edate? moment(machine.head_warranty_edate).format('DD.MM.YYYY') : null) }}
        </div>
        <div class="font-weight-light text-success font-small-2">
          <span v-if="moment(machine.head_warranty_edate).diff(moment(),'days') > 0">
            {{ moment(machine.head_warranty_edate).diff(moment(),'days') }} gün kaldı
          </span>
          <span v-else>Garanti süresi bitmiş</span>
        </div>
      </b-list-group-item>
      <list-item
        title="Durum"
        :value="machine.status? 'Kullanımda' : 'Kullanım Dışı'"
      />
      <list-item
        title="Notlar"
        :value="machine.notes"
      />
      <list-item
        title="Müşteri Adres Adı"
        :value="machine.address_title"
      />
    </b-list-group>
    <template v-if="!isReport">
      <hr>
      <div class="d-flex justify-content-between">
        <b-button
          v-b-toggle.machine-sidebar
          variant="primary"
          size="sm"
          @click="updateItem(machine.id)"
        >
          <FeatherIcon icon="EditIcon" />
          Güncelle
        </b-button>
        <b-button
          variant="outline-danger"
          size="sm"
          @click="removeMachine(machine.id)"
        >
          <FeatherIcon icon="XIcon" />
          Sil
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BListGroup, BListGroupItem, VBToggle,
} from 'bootstrap-vue'
import ListItem from '@/views/Admin/Customers/View/ListItem.vue'

export default {
  name: 'MachineCard',
  components: {
    ListItem,
    BCard,
    BButton,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    machine: {
      type: Object,
      required: true,
    },
    isReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUpdate: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerMachines/dataItem']
    },
  },
  methods: {
    updateItem(id) {
      this.$store.dispatch('customerMachines/getDataItem', id)
    },
    removeMachine(id) {
      this.$store.dispatch('customerMachines/removeData', id)
    },
  },
}
</script>
